<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Data User">
      <!-- Title -->
      <div class="pb-2">
        <b-avatar
          :src="blobImage ? blobImage : avatar"
          :text="avatarText('G')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="float-right pull-right mr-3">
          <span
            class="badge"
            :class="[
              formData.status == 'Aktif'
                ? 'badge-success'
                : formData.status == 'Tidak Aktif'
                ? 'badge-secondary'
                : 'badge-danger',
            ]"
            >{{ formData.status }}</span
          >
        </div>
      </div>
      <form @submit.prevent="submitForm()">
        <div class="mt-2">
          <b-row>
            <!-- Nama Lengkap -->
            <b-col md="6" class="mb-1">
              <label class="d-block">Nama Lengkap</label>
              <b-form-input
                readOnly
                v-model="formData.name"
                placeholder="Masukkan Nama Lengkap"
                :state="formErrors.name ? false : undefined"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.name ? formErrors.name[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <!-- Alamat Email -->
            <b-col md="6" class="mb-1">
              <label class="d-block">Email</label>
              <b-form-input
                readOnly
                v-model="formData.email"
                :state="formErrors.email ? false : undefined"
                placeholder="Masukkan Email"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.email ? formErrors.email[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Gelar Depan"
                :invalid-feedback="
                  formErrors.gelar_depan ? formErrors.gelar_depan[0] : ''
                "
                :state="formErrors.gelar_depan ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.gelar_depan"
                  placeholder="Gelar Depan"
                  :state="formErrors.gelar_depan ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Gelar Belakang"
                :invalid-feedback="
                  formErrors.gelar_belakang ? formErrors.gelar_belakang[0] : ''
                "
                :state="formErrors.gelar_belakang ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.gelar_belakang"
                  placeholder="Gelar Belakang"
                  :state="formErrors.gelar_belakang ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Tempat Lahir"
                :invalid-feedback="
                  formErrors.tempat_lahir ? formErrors.tempat_lahir[0] : ''
                "
                :state="formErrors.gelar_belakang ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.tempat_lahir"
                  placeholder="Tempat Lahir"
                  :state="formErrors.tempat_lahir ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Tanggal Lahir"
                :invalid-feedback="
                  formErrors.tanggal_lahir ? formErrors.tanggal_lahir[0] : ''
                "
                :state="formErrors.tanggal_lahir ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.tanggal_lahir"
                  placeholder="Tanggal Lahir"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Golongan"
                :invalid-feedback="
                  formErrors.golongan ? formErrors.golongan[0] : ''
                "
                :state="formErrors.golongan ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.golongan"
                  placeholder="Golongan"
                  :state="formErrors.golongan ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Jabatan"
                :invalid-feedback="
                  formErrors.jabatan ? formErrors.jabatan[0] : ''
                "
                :state="formErrors.jabatan ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.jabatan"
                  placeholder="Jabatan"
                  :state="formErrors.jabatan ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="Instansi"
                :invalid-feedback="
                  formErrors.instansi ? formErrors.instansi[0] : ''
                "
                :state="formErrors.instansi ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.instansi"
                  placeholder="Instansi"
                  :state="formErrors.instansi ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="NIP"
                :invalid-feedback="formErrors.nip ? formErrors.nip[0] : ''"
                :state="formErrors.nip ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.nip"
                  placeholder="NIP"
                  :state="formErrors.nip ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="NIK"
                :invalid-feedback="formErrors.nip ? formErrors.nip[0] : ''"
                :state="formErrors.nip ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.nik"
                  placeholder="NIK"
                  :state="formErrors.nip ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group
                label="No Telepon"
                :invalid-feedback="formErrors.phone ? formErrors.phone[0] : ''"
                :state="formErrors.phone ? false : undefined"
              >
                <b-form-input
                  readOnly
                  v-model="formData.phone"
                  placeholder="Nomor Telepon"
                  :state="formErrors.phone ? false : undefined"
                />
              </b-form-group>
            </b-col>

            <!-- Alamat -->
            <b-col lg="12" class="mb-1">
              <b-form-group
                label="Alamat"
                :invalid-feedback="
                  formErrors.address ? formErrors.address[0] : ''
                "
                :state="formErrors.address ? false : undefined"
              >
                <b-form-textarea
                  readOnly
                  v-model="formData.address"
                  :state="formErrors.address ? false : undefined"
                  placeholder="Alamat"
                  rows="3"
                  no-resize
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </form>
    </b-card>

    <b-card class="card mb-1" title="Daftar Pelatihan Diikuti">
      <div class="mt-2">
        <vue-good-table
          :line-numbers="true"
          :columns="columns"
          :rows="trainings"
          :select-options="{
            // enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            nextLabel: 'selanjutnya',
            prevLabel: 'sebelumnya',
            rowsPerPageLabel: 'Data per halaman',
            ofLabel: 'dari',
            pageLabel: 'halaman', // for 'pages' mode
            allLabel: 'Semua',
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'title'" class="text-nowrap">
              <router-link
                :to="{
                  name: checkAuthorizeRole('trainings.show'),
                  params: { trainingId: props.row.training_id },
                }"
                >{{ props.row.training.title }}</router-link
              >
            </span>
            <span
              v-else-if="props.column.field === 'is_done'"
              class="text-nowrap"
            >
              <b-badge :variant="props.row.is_done ? 'success' : 'secondary'">{{
                props.row.is_done ? "Selesai" : "Belum Selesai"
              }}</b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'certificate'"
              class="text-nowrap"
            >
              <a
                v-if="props.row.training_certificate"
                size="sm"
                class="mr-1 btn btn-primary btn-sm"
                target="__blank"
                :href="`${baseUrl}/training-certificates/${props.row.training_certificate.id}`"
              >
                Buka
              </a>
              <b-button v-else variant="secondary" size="sm"
                >Belum Ada</b-button
              >
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
// Components
import { VueGoodTable } from "vue-good-table";
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Utils
import { checkAuthorizeRole } from "@/auth/utils";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { avatarText } from "@core/utils/filter";
import { reqGetUser, reqUpdateUser } from "@/api/admin/user";

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormText,
    BBadge,
    vSelect,
    flatPickr,
    VueGoodTable,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      blobImage: "",
      avatar: "",

      formErrors: {},

      religion: ["Islam", "Kristen", "Katolik", "Hindu", "Budha", "Konghucu"],

      formData: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        address: "",
        avatar: "",
        status: "",
        gelar_depan: "",
        gelar_belakang: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        golongan: "",
        jabatan: "",
        instansi: "",
        nip: "",
        nik: "",
      },
      trainings: [],
      columns: [
        {
          label: "Nama Pelatihan",
          field: "title",
          filterOptions: {
            styleClass: "class1", // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: "Cari pelatihan", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: "keyup", // only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "is_done",
        },
        {
          label: "Sertifikat",
          field: "certificate",
        },
      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? "EyeOffIcon" : "EyeIcon";
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_API_URL + "/api";
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    checkAuthorizeRole,
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0];
      this.formData.avatar = file;
      this.blobImage = URL.createObjectURL(file);
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
    validateForm() {
      const isValid = true;

      return isValid;
    },
    async loadUser() {
      await reqGetUser(this.$route.params.id)
        .then((response) => {
          this.avatar = response.data.data.avatar;
          this.formData.name = response.data.data.name;
          this.formData.email = response.data.data.email;
          this.formData.status = response.data.data.status;

          this.formData.phone = response.data.data.profile.phone;
          this.formData.address = response.data.data.profile.address;
          this.formData.gelar_depan = response.data.data.profile.gelar_depan;
          this.formData.gelar_belakang =
            response.data.data.profile.gelar_belakang;
          this.formData.tempat_lahir = response.data.data.profile.tempat_lahir;
          this.formData.tanggal_lahir =
            response.data.data.profile.tanggal_lahir;
          this.formData.golongan = response.data.data.profile.golongan;
          this.formData.jabatan = response.data.data.profile.jabatan;
          this.formData.instansi = response.data.data.profile.instansi;
          this.formData.nip = response.data.data.profile.nip;
          this.formData.nik = response.data.data.profile.nik;
          this.trainings = response.data.data.training_participants ?? [];
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "Data tidak ditemukan"
          );
        });
    },
    async submitForm() {
      this.isLoading = true;
      if (this.validateForm()) {
        const data = new FormData();
        for (let i in this.formData) {
          if (this.formData[i]) {
            data.append(i, this.formData[i]);
          }
        }

        await reqUpdateUser(this.$route.params.id, data)
          .then((response) => {
            this.isLoading = false;
            this.formErrors = {};

            this.showToast(
              "success",
              "Check",
              "Berhasil",
              response.data.message || "User Berhasil Disimpan"
            );

            this.loadUser();
          })
          .catch((error) => {
            this.isLoading = false;
            this.formErrors = error.response.data.errors;

            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Gagal",
              error.response.data.message || "User Gagal Disimpan"
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-good-table.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
